@import url("https://use.typekit.net/edy7qfd.css");

:root {
  font-family: futura-pt, sans-serif;
  * {
    font-family: futura-pt, sans-serif;
  }
  
  color: #000000;
  --border-color: #383b49;
  --header-bg-color: rgb(80,83,97);
  --logo-color: #FFFFFF;
  ---card-bg-color: rgb(212,216,232);
  --bg: hsl(0, 0%, 0%);
  --glass-bg: rgba(0, 0, 0, 0.4);
  --card-img-bg: rgb(222,170,170);

  background-color: hsl(0, 0%, 100%);
  background-image: url(./assets/bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

body {
  font-family: futura-pt, sans-serif;
}
