.left_column_div {
  width: 24rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.middle_main_div {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  gap: 2rem;

  .right_column_div {
    height: 570px;
    width: 700px;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    padding-bottom: 1rem;
    box-sizing: border-box;
    
    border: 2px solid var(--border-color);
    border-radius: 18px;
    overflow: hidden;
    box-shadow: 0 0 6px rgb(0 0 0 / 65%);
    background: rgb(157, 165, 203);

    .card_display_div {
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: center;

      overflow: hidden;

      .loader_img_div {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;

        img {
          aspect-ratio: 1 / 1;
          height: 50px;
          width: 50px;
        }
      }

      .card_scroll_div {
        max-height: 100%;
        width: 36rem;

        padding: 0.75rem 0.75rem;

        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: flex-start;
        gap: 1rem;

        overflow-y: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .card_scroll_div.scroll_border {
        box-shadow: 0 -8px 6px -6px rgb(0 0 0 / 65%);
      }
    }
  }
}

@media only screen and (min-width: 800px) and (max-width: 1268px) {
  .left_column_div {
    width: 20rem;
    gap: 1rem;
  }

  .middle_main_div {
    width: 100%;
    height: 570px;
    gap: 1rem;

    .right_column_div {
      height: 570px;
      width: 450px;

      .card_display_div {

        .card_scroll_div {
          width: 25rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .middle_main_div {
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .right_column_div {
      height: 435px;
      width: 90%;

      gap: 1rem;

      margin-bottom: 1rem;

      .card_display_div {
        width: 18.5rem;
        margin: 0 auto;

        .card_scroll_div {
          gap: 1rem;
          margin-bottom: 1rem;
        }
      }
    }

    .left_column_div {
      width: 90%;
      gap: 1rem;
    }
  }
}