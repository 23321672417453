.navbar_main_div {
	padding: 0.5rem;
	background: var(--header-bg-color);
	border-bottom: 2px solid #000000;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;

	.route_btn {
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--bg);
		width: 178px;
		height: 42px;
		color: gray;
		font-size: 22px;
		cursor: pointer;
		border: 2px solid var(--border-color);
		transition: all 0.2s ease-in-out;

		span {
			margin-left: 7px;
			font-size: 16px;
			height: 20px;
			color: gray;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			opacity: 0.6;
		}

		&:first-child {
			border-radius: 12px 0 0 12px;
		}

		&:last-child {
			border-radius: 0 12px 12px 0;
		}

		&:hover {
			box-shadow: 0 0 8px rgb(251 251 251 / 40%);
			text-color: var(--border-color);
		}
	}

	.route_btn.border_class {
		border: 2px solid var(--logo-color);
		color: var(--logo-color);
	}
}

@media only screen and (min-width: 768px) and (max-width: 1268px) {
	.navbar_main_div {
		// height: 3rem;

		.route_btn {
			width: 138px;
			height: 36px;
			font-size: 18px;

			span {
				font-size: 12px;
				height: 16px;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.navbar_main_div {
		// height: 2.2rem;

		.route_btn {
			width: 102px;
			height: 24px;
			font-size: 14px;

			span {
				font-size: 10px;
				height: 10px;
			}
		}

		.route_btn.border_class {
			border: 1px solid var(--logo-color);
		}
	}
}