.terms-container {
    height: 1rem;  

    position: relative;

    // outline: 1px solid #000;

    .toggle-btn {
        position: absolute;
        inset: auto auto 0rem 1.5rem ;

        width: 10.0rem;
        height: 2.0rem;

        border-radius: 0.5rem;
        border: none;

        background-color: #505361;
        outline: 2px solid #000;

        font-size: 1rem;
        font-weight: 600;
        color: #000;

        box-shadow: 0px 0px 6px rgb(0 0 0 / 65%);

        cursor: pointer;
    }

    .terms-popup-screen {
        position: fixed;
        inset: 0 0;

        width: 100%;
        height: 100vh;

        background: hsla(0, 0%, 0%, 0.5);

        display: grid;
        place-content: center;

        opacity: 0;
        pointer-events: none;

        transition: all 0.3s ease-in-out;

        .terms-popup-container {
            width: min(35rem, 90vw);

            background-color: #9da5cb;
            border: 2px solid #000;
            border-radius: 1rem;
            overflow: hidden;

            box-shadow: 0px 0px 6px rgb(0 0 0 / 65%);

            transform: translateY(2rem);
            opacity: 0;

            transition: all 0.3s ease-in-out;

            h1 {
                padding: 0.5rem 1.5rem;
                
                // text-align: center;
                font-size: 1.25rem;
                
                background-color: #505361;
                border-bottom: 2px solid #000;

                position: relative;

                button {
                    position: absolute;

                    inset: 0rem 0.5rem auto auto;
                    height: 100%;
                    aspect-ratio: 1;
                    border-radius: 5rem;

                    background: transparent;
                    
                    display: grid;
                    place-content: center;

                    cursor: pointer;

                    svg {
                        font-size: 1.5rem;
                    }
                }
            }

            .content {
                padding: 2rem 1.5rem;
                height: 30rem;

                overflow-y: auto;
            }
        }
    }
}

@media only screen and (max-width:768px) {
    .terms-container {
        height: 3rem;

        .toggle-btn {
            inset: auto auto 1rem 5%;
        }
    }
}