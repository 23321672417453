.logo_div{
  height: 5rem;
  width: 24rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  border: 2px solid var(--bg);
  overflow: hidden;
  box-shadow: 0 0 6px rgb(0 0 0 / 65%);
  img{
    width: 24rem;
    height: 5rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1268px){
.logo_div{
  height: 3.5rem;
  width: 16rem;
  img{
    width:16rem;
    height: 3.5rem;
  }
}
}

@media only screen and (max-width: 768px){
  .logo_div{
    height: 3rem;
    width: 15rem;
    img{
      width:15rem;
      height: 3rem;
    }
  }
}