.collection_main_div {
  width: 100%;
  padding: 1rem 1rem 0.5rem;
  box-sizing: border-box;

  background-color: #505361;
  border-radius: 18px;
  border: 2px solid #383b49;
  box-shadow: 0 0 6px rgb(0 0 0 / 65%);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  * {
    transition: all 0.3s ease-in-out;
  }

  .header {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 1.125rem;
  }

  .collection_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    .collection_name {
      display: grid;
      justify-items: center;
      
      label {
        display: grid;
        justify-items: center;
        gap: 0.25rem;
        width: 6.5rem;
        height: 10rem;

        border-radius: 0.5rem;

        text-align: center;

        padding: 0.15rem;

        box-sizing: border-box;

        cursor: pointer;

        .img-container {
          width: 100%;
          aspect-ratio: 1;
          padding: 0.5rem;
          box-sizing: border-box;
          
          background-color: #d4d8e8;
          border-radius: 0.5rem;
          border: 2px solid #383b49;
          
          img {
            width: 100%;
            margin-bottom: -0.25rem;
            border-radius: 0.25rem;
            
            box-shadow: 0px 1px 4px hsla(0, 0%, 0%, 0.75);
            outline: 0.075rem solid #383b49;
          }
        }

        span {
          max-width: 6.15rem;
          word-wrap: break-word;
          hyphens: auto;
        }
      }

      input[type="radio"] {
        visibility:hidden;
        width: 0rem;
        height: 0rem;
      }

      input[type="radio"]:checked + label {
        .img-container {
          box-shadow: 0px 0px 0px 2px #FFF;
        }
        color: #FFF;
        font-weight: 600;
      }

      input[type="radio"]:disabled:not(:checked) + label {
        cursor: default;
        position: relative;
        
        .img-container {
          filter: grayscale(1) brightness(0.45);
        }

        &::after {
          content: "COMING SOON";
          position: absolute;

          width: 100%;
          height: min-content;

          inset: 2rem 0.25rem;
          transform: rotateZ(-30deg);
          transform-origin: center;

          color: #809dff;
          font-size: 1.125rem;
          font-weight: 900;
          text-shadow: 0px 0px 2px #000;
        }
      }
    }

  }
}

@media only screen and (min-width: 768px) and (max-width: 1268px) {
  .collection_main_div {
    padding: 1rem 0.5rem 0.5rem;

    .collection_container {
      gap: 0.5rem;

      .collection_name {
        label {
          width: 5.5rem;
          padding: 0rem;

          span {
            max-width: 5.5rem;
            word-wrap: break-word;
            hyphens: auto;
          }
        }

        input[type="radio"]:disabled:not(:checked) + label {
          &::after {
            inset: 1.5rem 0.25rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width:768px) {
  .collection_main_div {

    .collection_container {
      gap: 0.25rem;

      .collection_name {
        max-width: 30%;

        label {
          max-width: 85%;
          padding: 0rem;

          span {
            max-width: 5rem;
            word-wrap: break-word;
            hyphens: auto;
          }
        }

        input[type="radio"]:disabled:not(:checked) + label {
          &::after {
            inset: 1.5rem 0.25rem;
          }
        }
      }
    }
  }
}