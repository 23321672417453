.header-container {    
    height: fit-content;
    
    padding: 2rem 0rem 3rem 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .hkc-logo {
        height: 3.5rem;
    }

    .social-logo {
        height: 2.5rem;
        margin-inline: 0.5rem;
        object-fit: contain;
        object-position: center;
    }
}

@media only screen and (max-width: 768px) { 
    .header-container {
        gap: 0rem;

        .hkc-logo {
            height: auto;
            width: 65%;
        }

        .social-logo {
            height: auto;
            width: 65%;
            margin-inline: 0rem;
        }
    }
}