#root {
    margin: 0;
    font-family: 'Futura';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font-family:'Futura';
}
