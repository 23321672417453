.connect_main_div {
  width: 100%;
  border: 2px solid #383b49;
  border-radius: 18px;
  // margin-top: 2rem;
  box-shadow: 0 0 6px rgb(0 0 0 / 65%);
  overflow: hidden;
  button {
    cursor: pointer;
    background: var(--bg);
    border-radius: 10px;
    box-shadow: 0 0 8px rgb(0 0 0 / 40%);
    border: 1px solid hsla(120, 2%, 53%, .5);
    color: #FFFFFF;
    font-size: 20px;
    transition: box-shadow 0.2s ease-in-out;
    &:hover{
      box-shadow: 0 0 8px rgb(251 251 251 / 40%);
    }
    &:disabled{
      opacity: 0.7;
      pointer-events: none;
    }
  }

  .header_div {
    height: 3rem;
    color: #FFFFFF;
    border-bottom: 2px solid black;
    background: var(--header-bg-color);
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 18px;
    font-weight: bold;
    .wallet_address_div{
      display: flex;
      align-items: center;
      .wallet_logo {
        font-size: 22px;
        margin-right: 1rem;
      }
    }
    .disconnect_btn{
      font-size: 15px !important;
      color:#fa1414;
      padding: 3px 10px;
    }
  }

  .details_div {
    min-height: 8rem;
    box-shadow:inset 0 0 5px rgb(0 0 0 / 20%);
    background: rgb(157,165,203);

    .connect_btn_div {
      height: 8rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .connect_btn {
        width: 142px;
        height: 42px;
      }
    }
    .select_text{
      font-size: 20px;
    }
    .selectAll_div {
      height: 10rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.2rem;
      .approve_text{
        font-size: 20px;
        text-align: center;
        width: 80%;
      }
      .approve{
        width: 142px;
        height: 38px;
      }
      .stakeAll{
        width: 152px;
        height: 42px;
      }
    }
    .double_btn_div{
      min-height: 8rem;
      box-shadow:inset 0 0 5px rgb(0 0 0 / 20%);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      button{
        width: 118px;
        height: 42px;
      }
      .stake{
        color: rgb(11,249,0);
        &:hover{
          box-shadow: 0 0 8px rgb(11 249 0 / 40%);
        }
      }
      .cancel{
        color:#fa1414;
        &:hover{
          box-shadow: 0 0 8px rgb(250 20 20 / 40%);
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1268px){
  .connect_main_div{
    button{
      font-size: 18px;
    }
    .header_div{
      height: 2.4rem;
      .wallet_address_div{
        font-size: 16px;
        .wallet_logo {
          font-size: 16px;
          margin-right: 0.8rem;
        }
      }
      .disconnect_btn{
        font-size: 13px !important;
      }
    }
    .details_div {
      min-height: 8rem;
      .connect_btn_div {
        height: 8rem;
        .connect_btn {
          width: 122px;
          height: 38px;
        }
      }
      .select_text{
        font-size: 18px;
      }
      .selectAll_div {
        height: 8rem;
            gap: 1rem;

        .approve_text{
          font-size: 18px;
        }
        .approve{
          width: 128px;
          height: 35px;
        }
            .stakeAll{
              width: 132px;
              height: 38px;
            }
          }

     .double_btn_div{
       min-height: 8rem;
       button{
         width: 98px;
         height: 38px;
       }
     }
    }
  }
}

@media only screen and (max-width: 768px) {
  .connect_main_div{
    width: 100%;
    button{
      font-size: 16px;
    }
    .header_div{
      height: 1.8rem;
      font-size: 16px;
      .wallet_address_div{
        font-size: 16px;
        .wallet_logo {
          font-size: 14px;
          margin-right: 0.8rem;
        }
      }
      .disconnect_btn{
        font-size: 12px !important;
        color:#fa1414;
      }
    }
    .details_div {
      min-height: 8rem;
      .connect_btn_div {
        height: 8rem;
        .connect_btn {
          width: 96px;
          height: 32px;
        }
      }
      .select_text{
        font-size: 16px;
      }
      .selectAll_div {
        height: 8rem;
        gap: 0.8rem;
        .approve{
          width: 112px;
          height: 30px;
        }
        .approve_text{
          font-size: 16px;
          width: 90%;
        }
        .stakeAll{
          width: 112px;
          height: 32px;
        }
      }

      .double_btn_div{
        min-height: 8rem;
        button{
          width: 82px;
          height: 32px;
        }
      }
    }
  }
}