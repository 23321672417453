.single_card {
  // height: min-content;
  width: 10rem;
  border-radius: 1.5rem;
  cursor: pointer;
  box-shadow: 0 0 8px rgb(0, 0, 0, 0.2);
  border: 2px solid black;
  background: var(---card-bg-color);
  padding: 0.5rem 0.5rem 0.75rem;
  transition: all 0.2s ease-in-out;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .img_div {
    position: relative;
    background: var(--card-img-bg);
    border-radius: 1.125rem;
    height: 9.5rem;
    width: calc(10rem - 2px);
    border: 1px solid gray;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
    }

    .timer_div {
      position: absolute;
      bottom: 2px;
      height: 23px;
      width: 6.6rem;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-size: 13px;

      .icon {
        fill: white;
        margin-right: 3px;
        height: 14px;
        width: 14px;
      }
    }
  }

  .card_id_div {
    height: 2.8rem;
    width: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;

    span.selected_span {
      color: #0044FF;
    }
  }

  .stake_info {
    background-color: #5c606a;
    width: 95%;
    padding: 0.5rem;
    box-sizing: border-box;

    border-radius: 0.75rem;

    display: grid;
    justify-items: center;

    box-shadow: 0px 0px 6px hsla(0, 0%, 0%, 0.5);

    p {
      color: #FFFFFF;
      font-size: 0.925rem;
      
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }

    .time {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &:first-of-type {
        margin-top: 0.25rem;
      }

      p:first-of-type {
        font-size: 0.85rem;
      }
    }
  }

  &:hover {
    box-shadow: 0 0 12px #0044FF;
  }
}

.single_card.selected {
  // border: 2px solid #0044FF;
  outline: 2px solid #0044FF;
}

.single_card.disabled {
  pointer-events: none;
}

@media only screen and (min-width: 768px) and (max-width: 1268px) {
  .single_card {
    // height: 8.2rem;
    width: 7rem;
    padding: 0.35rem 0.2rem 0.5rem;
    border-radius: 1.25rem;

    .img_div {
      height: 6.5rem;
      width: calc(6.8rem - 2px);
      border-radius: 0.975rem;

      img {
        height: 100%;
        width: 100%;
      }

      .timer_div {
        bottom: 1px;
        height: 16px;
        width: 4.8rem;
        border-radius: 5px;
        font-size: 10px;

        .icon {
          height: 11px;
          width: 11px;
        }
      }
    }

    .stake_info {
      p {
        font-size: 0.8rem;
      }

      .time {
        p:first-of-type {
          font-size: 0.6rem;
        }
      }
    }

    .card_id_div {
      height: 1.8rem;
      width: 6.8rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .single_card {
    width: 7rem;
    padding: 0.35rem 0.35rem 0.5rem;
    border-radius: 1rem;
    gap: 0.5rem;

    .img_div {
      height: 6.5rem;
      aspect-ratio: 1;
      width: 100%;
      border-radius: 0.75rem;

      img {
        height: 100%;
        width: 100%;
      }

      .timer_div {
        height: 13px;
        width: 4rem;
        border-radius: 5px;
        font-size: 8px;

        .icon {
          height: 9px;
          width: 9px;
        }
      }
    }

    .stake_info {
      width: 100%;
      padding: 0.25rem 0.75rem;
      p {
        font-size: 0.75rem;
      }
      .time {
        p:first-of-type {
          font-size: 0.65rem;
        }
      }
    }

    .card_id_div {
      height: 1.2rem;
      width: 5.4rem;
      font-size: 14px;
    }
  }
}